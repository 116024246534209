<script setup lang="ts">
import type { NavigationItem } from '@mop/ui/types/uiComponents';
import type { Timer } from '@mop/types';
import SVGFilter from '@mop/shared/images/misc/filter.svg?component';
// @ts-ignore
import cssExport from '@/assets/scss/export.module.scss';
import type { FilterModel } from '@/types/filters';
import type { CategoryModel } from '@/types/category';

defineOptions({
  name: 'CategoryTopNavigation',
});

const props = defineProps({
  pathCategories: {
    type: Array as PropType<CategoryModel[]>,
    default: () => [],
  },
  headline: {
    type: String,
    required: true,
  },
  storyblokEditable: {
    type: Object,
    default: null,
  },
  showFilter: {
    type: Boolean,
    default: false,
  },
});

const { $scroll, $resize, $breakpoint, $mopConfig, $mopI18n, $gtm2 } = useNuxtApp();
const { activeOverlayRef } = useMopOverlay();
const {
  toggleFilterOverlay,
  filterOverlayComponentName,
  showFilterUsedCheeringMessageRef,
  filterUsedCheeringMessageRef,
} = useMopFilterOverlay();
const { appliedFiltersRef, appliedSortingRef } = useMopProducts();
const rootElementRef = ref();
const showFilterCheeringMessageRef = ref(false);
const filterCheeringMessageRef = ref('');

onMounted(() => {
  let timer: Timer;
  watch(
    [() => $scroll.offsetTopRef.value, () => $resize.viewportHeightRef.value, () => $breakpoint.currentRef.value],
    () => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        const { height, top } = rootElementRef.value.parentNode.getBoundingClientRect();
        let topOffset = Math.max(0, top + height);
        if ($breakpoint.isMobileRef.value) {
          topOffset = Math.max(parseInt(cssExport['theme-mobile-header-height']), top);
        }
        document.getElementById('wrapper')?.style.setProperty('--navh', `${topOffset}px`);
      }, 350);
    },
    { immediate: true },
  );

  handleFilterCheeringMessage();
});

async function handleFilterCheeringMessage() {
  const { getFilterCheeringMessage, showFilterCheeringMessage } = useMopCheeringMessageClient();
  if (showFilterCheeringMessage()) {
    showFilterCheeringMessageRef.value = true;
    filterCheeringMessageRef.value = await getFilterCheeringMessage();
  }
}

const filterCountRef = computed(() => {
  let filterCount: number =
    appliedFiltersRef.value?.reduce((count: number, filter: FilterModel) => {
      return count + filter.getValues().length;
    }, 0) || 0;
  if (appliedSortingRef.value) {
    filterCount++;
  }
  return filterCount;
});

const categoryIdRef = computed(() => {
  const pathCategories = props.pathCategories;
  const size = pathCategories.length;

  if (Array.isArray(pathCategories) && size > 0) {
    return pathCategories[size - 1].getId();
  }
  return 0;
});

const headlineUrlRef = computed(() => {
  const size = props.pathCategories.length;
  if (size === 0) {
    return;
  }

  return $mopI18n.localePath(size > 3 ? props.pathCategories[2].getUrl() : props.pathCategories[size - 1].getUrl());
});

const navigationItemsRef = computed(() => {
  const pathCategories = props.pathCategories;
  const size = pathCategories.length;

  const categories: CategoryModel[] =
    size > 3 ? pathCategories[2].getChildren() : pathCategories[size - 1]?.getChildren() ?? [];

  return categories.map((category): NavigationItem => {
    return {
      id: category.getId(),
      url: $mopI18n.localePath(category.getUrl()),
      name: category.getName($mopConfig),
      isActive: categoryIdRef.value === category.getId(),
      isOpaque: pathCategories.length === 4 && categoryIdRef.value !== category.getId(),
    };
  });
});

function openFilterOverlay() {
  if (showFilterCheeringMessageRef.value) {
    showFilterCheeringMessageRef.value = false;
  }
  handleEventTrack('filterButton', document.location.href);
  return toggleFilterOverlay();
}

function handleEventTrack(event: string, label: string) {
  const route = useRoute();
  $gtm2.reportLegacyEngagement({
    event,
    category: $gtm2.getCurrentPage() || String(route.name) || 'N/A',
    label,
  });
}

function reportCategoryClick(clickedItem: string, eventName: string) {
  $gtm2.reportLegacyEngagement({
    event: eventName,
    category: $gtm2.getCurrentPage(),
    label: clickedItem,
    parameter3: $gtm2.getPageCategoryId(),
  });
}

function trackCategoryHeadlineClick(categoryName: string) {
  reportCategoryClick(categoryName, 'navigation_back_to_category');
}

function trackSubcategoryClick(clickedItem: string) {
  reportCategoryClick(clickedItem, 'navigation_subcategory');
}

const hasSubCategories = computed(() => {
  return props.pathCategories.length >= 3 && navigationItemsRef && navigationItemsRef.value.length > 0;
});
</script>

<template>
  <div
    ref="rootElementRef"
    :class="[
      'category-top-navigation',
      {
        'category-top-navigation--inactive': activeOverlayRef && activeOverlayRef.isOpen,
        'category-top-navigation--extended': hasSubCategories,
      },
    ]"
  >
    <NuxtLink
      v-if="headline"
      :to="headlineUrlRef"
      class="category-top-navigation__wrapper"
      no-prefetch
      @click="trackCategoryHeadlineClick(headline)"
    >
      <h1 :key="headline" v-storyblok-editable="storyblokEditable" class="category-top-navigation__header">
        {{ headline }}
      </h1>
    </NuxtLink>

    <ClientOnly>
      <div class="category-top-navigation__cheering-wrapper">
        <div v-if="showFilterUsedCheeringMessageRef" class="category-top-navigation__cheering-message-filter-used">
          <MopCheeringMessage :message="filterUsedCheeringMessageRef" show-icon />
        </div>

        <MopCheeringMessage
          v-else-if="showFilterCheeringMessageRef"
          :message="filterCheeringMessageRef"
          background-color="purple"
          show-arrow
          :fade-out-in-seconds="7"
        />
      </div>
    </ClientOnly>

    <UiScrollNavigation
      v-if="hasSubCategories"
      :navigation-items="navigationItemsRef"
      :is-nav-inactive="Boolean(activeOverlayRef && activeOverlayRef.isOpen)"
      @item-click="trackSubcategoryClick"
    />

    <div v-if="showFilter" class="category-top-navigation__actions">
      <button
        :class="[
          'category-top-navigation__show-filters toggle-opacity',
          {
            active: activeOverlayRef && activeOverlayRef.componentName === filterOverlayComponentName,
          },
        ]"
        :data-items="filterCountRef > 0 ? filterCountRef : ''"
        @click.prevent="openFilterOverlay"
      >
        <span class="desktop-only">
          {{ $mopI18n.t('components.plp.filters') }}{{ filterCountRef > 0 ? `(${filterCountRef})` : '' }}
        </span>
        <SVGFilter class="mobile-only category-top-navigation__show-filters-icon" width="32" height="32" />
      </button>
    </div>
  </div>
</template>

<style scoped lang="scss">
.category-top-navigation {
  display: grid;
  grid-template-areas: 'left center right';
  grid-template-columns: minmax(100px, max-content) minmax(100px, 1fr) min-content;
  width: 100%;
  padding: $space5 $global-padding;

  @include apply-upto(medium) {
    grid-template-areas:
      'left right'
      'center .';
    padding: $space5 $global-padding 0 $global-padding;

    .category-list--sticky-nav-prepare & {
      margin-top: 0;
      grid-row-gap: 0;
      padding-top: 0;
      padding-bottom: 0;

      .category-top-navigation__cheering-wrapper {
        opacity: 0;
      }
    }

    .category-list--sticky-nav & {
      .category-top-navigation__cheering-wrapper {
        opacity: 1;
      }
    }
  }
}

.category-top-navigation__wrapper {
  display: flex;
  grid-area: left;

  @include apply-upto(medium) {
    align-items: center;
  }
}

.category-top-navigation__show-categories,
.category-top-navigation__show-filters {
  white-space: nowrap;
  text-transform: uppercase;
  background: transparent;
  border: 0;
  padding: 0;
  margin: 0;
  display: flex;
  cursor: pointer;

  @include apply-upto(medium) {
    align-items: flex-end;
  }
}

.category-top-navigation__show-arrows {
  @include apply-upto(medium) {
    margin-bottom: $space2;
  }
}

.category-top-navigation__show-categories {
  @include text-style(strong);

  margin: 0 0 0 $space20;
}

.category-top-navigation__show-filters {
  @include link-neutral(10px);
  @include text-style(strong);

  margin: 0 0 0 $space20;

  @include apply-upto(medium) {
    @include exponent-text-style(0, 31px);

    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 0;
    padding: 0;
    width: $mobile-cart-icon-width;
  }
}

.category-top-navigation__show-filters-icon {
  margin-right: -10px;
}

.category-top-navigation__header {
  @include text-style(headline-condensed);

  margin: 0;
  padding: 2px 0;
  text-transform: uppercase;
  line-height: 27px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.category-top-navigation--extended {
  @include apply-upto(medium) {
    .category-list--sticky-nav-prepare & {
      grid-template-areas: 'left center right';
      grid-template-columns: minmax(100px, max-content) minmax(100px, 1fr) min-content;

      .category-top-navigation__wrapper {
        display: none;
      }
    }
  }

  .category-top-navigation__header {
    max-width: 40vw;

    @include apply-upto(medium) {
      max-width: none;
    }
  }
}

.category-top-navigation__actions {
  grid-area: right;
  display: flex;

  @include apply-upto(medium) {
    padding-bottom: 0;
    align-items: center;
    justify-content: flex-end;
  }
}

.category-top-navigation--inactive {
  .toggle-opacity {
    opacity: $disabled-opacity;
  }

  .active {
    opacity: 1;
  }
}

.desktop-only {
  display: flex;

  @include apply-upto(medium) {
    display: none;
  }
}

.mobile-only {
  display: flex;

  @include apply-from(large) {
    display: none;
  }
}

.scroll-into-view {
  scroll-snap-align: center;
}

.category-top-navigation__cheering-wrapper {
  @include z(global, abovecontent);

  position: absolute;
  left: 0;
  right: 0;
  top: $mobile-header-height;
  font-size: 12px;
  :deep(.cheering-message--arrow::after) {
    right: 18px;
  }
}

.category-top-navigation__cheering-message-filter-used {
  margin: 0 $global-padding;
}
</style>
