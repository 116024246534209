<script setup lang="ts">
defineOptions({
  name: 'CategorySearchNoResultPage',
});

const emit = defineEmits(['load-state']);

const { $storyblokLivePreview } = useNuxtApp();
const route = useRoute();
const cacheId = getCacheIdByRoute(route);
const { getCmsStory, cmsStoryModelRef, loadingRef } = useMopCms(cacheId);
const { handleHeadForCmsPage } = useMopSeo();

watch(
  () => loadingRef.value.loading,
  (isLoading) => {
    emit('load-state', isLoading);
  },
);

const seoHeadRef = computed(() => {
  if (!cmsStoryModelRef.value.isInitialized() || cmsStoryModelRef.value.hasError()) {
    return;
  }
  return handleHeadForCmsPage(cmsStoryModelRef.value);
});

useHead({
  title: () => seoHeadRef.value?.title as any,
  meta: () => seoHeadRef.value?.meta as any,
  link: () => seoHeadRef.value?.link as any,
});

useAsyncData(async () => {
  await getCmsStory(constants.STORYBLOK.STORY_PATHS.SEARCH_NO_RESULT);
});

function getSearchQueryValue() {
  return route.query[constants.QUERY_PARAMETERS.SEARCH_QUERY];
}

$storyblokLivePreview.initStoryListener('Storyblok', cmsStoryModelRef, true);
</script>

<template>
  <div class="search-no-result">
    <div class="search-no-result__header">
      <h1 class="search-no-result__headline">
        {{ $mopI18n.t('components.search.nohits.header', [getSearchQueryValue()]) }}
      </h1>
      <p class="search-no-result__message">
        {{ $mopI18n.t('components.search.nohits.message') }}
      </p>
      <LazyMopSearchSiteForm />
    </div>

    <MopCmsContentElementsSlot
      id="search-no-result__content-slot"
      class="search-no-result__content-slot"
      :elements="cmsStoryModelRef.getContentElements()"
      :campaign-name="cmsStoryModelRef.getCampaignName()"
    />

    <MopRecommendation class="search-no-result__recommendation" widget="ERROR_PAGE" section="SEARCH" show-slider-peek />
  </div>
</template>

<style lang="scss" scoped>
.search-no-result {
  margin-top: $space30;
  margin-bottom: $space60;

  @include apply-upto(small) {
    margin-top: $space20;
    margin-bottom: $space40;
  }
}

.search-no-result__content-slot {
  margin: $space50 0;
}

.search-no-result__recommendation {
  margin-top: $space50;
  padding: 0 $global-padding;
}

.search-no-result__header {
  padding: 0 $global-padding;
}

.search-no-result__headline {
  @include text-style(headline);
}

.search-no-result__message {
  @include text-style(running);

  margin-bottom: $space30;
}
</style>
