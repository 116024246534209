<script setup lang="ts">
import type { ObserveScrollConfig, ActionPosition } from '@mop/types';
import { throttle, isClient } from '@mop/shared/utils/util';
import CategoryTopNavigation from './components/CategoryTopNavigation.vue';
import CategoryBottomNavigation from './components/CategoryBottomNavigation.vue';
import type { GtmProductListType } from '@/types/gtm';
import type { FilterModel } from '@/types/filters';
import type { ProductModel } from '@/types/product';
import type { CmsPromoListTile } from '@/composables/useMopCategoryListPage';
import type { SeoBreadcrumb } from '@/types/cms';

const FILTER_COLOR = 'refinementcolor';

type CategoryHeadline = {
  h1: string;
  seo: string;
};

defineOptions({
  name: 'CategoryListPage',
});

const emit = defineEmits(['load-state', 'empty-search']);

const { $breakpoint, $storyblokLivePreview, $mopConfig, $mopI18n, $gtm2 } = useNuxtApp();
const { getQueryParamValue, getLocalePathByPage, activeCategoryPathRef } = useMopRouter();
const route = useRoute();
const { getCategoryByPath, getCategoriesInPath, getCategoryBreadcrumbs } = useMopCategoryTree();
const { getHeadObject, setAlternates, getBreadcrumbSchema } = useMopSeo();
const cacheId = getCacheIdByRoute(route);
const { customerModelRef } = useMopCustomer();
const {
  productModelListRef,
  searchProducts,
  paginationModelRef,
  loadingProductsRef,
  updateProductCount,
  setAppliedSortingFromUrl,
  initFilters,
  categoryFiltersModelRef,
  appliedFiltersRef,
  filterList,
  loadingFiltersRef,
} = useMopProducts(`${cacheId}-products`);

const {
  productModelListRef: productModelListRefWithFuzzyFallback,
  searchProductsWithFuzzyFallback,
  sortProductModelListByFuzzy,
  isFuzzySearchRef,
  paginationModelRef: paginationModelRefWithFuzzyFallback,
  loadingProductsRef: loadingProductsRefWithFuzzyFallback,
} = useMopProductSearch();

const overlay = useMopOverlay();
const path = (route.params.path as string) ?? '';
const category = getCategoryByPath(path);
const blackListedQueryParameters: string[] = [
  'clientonly',
  'sort',
  'q',
  'page',
  'disablesuperbanner',
  'disablecookiebanner',
  'disablegtm',
  'disableabtasty',
  'disablecartwishlist',
  'cachevalue',
  'cv',
  '_storyblok',
  '_storyblok_c',
  '_storyblok_lang',
  '_storyblok_release',
  '_storyblok_tk%5Btimestamp%5D',
  'login',
  'cbd',
  'pmm',
  ...filterList,
];
const isStoryblokLivePreview: boolean = $storyblokLivePreview.isEnabled;
const indexableSingleColorFilterRef = ref<FilterModel | null>(null);
let isSeoFilterUrlsEnabled: boolean = $mopConfig.isSeoFilterUrlsEnabled() && [2, 3].includes(category.getDepth());
if (isSeoFilterUrlsEnabled) {
  const parentSecondLevelCategoryMopId: string = category.getMopId().split('-').slice(0, 2).join('-');
  isSeoFilterUrlsEnabled =
    $mopConfig.getCategoryPreference(parentSecondLevelCategoryMopId).isSeoFilterUrlsEnabled === true;
}
const filterBottomNavigationListRef = computed(() => {
  if (!isSeoFilterUrlsEnabled) {
    return [];
  }
  const colorFilter: FilterModel | undefined = categoryFiltersModelRef.value
    .getFilterModelList()
    .find((filter) => filter.getSlug() === FILTER_COLOR);
  if (!colorFilter) {
    return [];
  }
  return [colorFilter];
});

const loadContentClientOnlyRef = useMopSSR('load-content-client-only', false);

if (!isClient) {
  // Based on CDN cache key
  loadContentClientOnlyRef.value = Boolean(route.query?.clientonly);
}
const isNavStickyRef = ref(false);
const isNavPrepareStickyRef = ref(false);

// categoryModelRef is needed only for fetching additional data, that is not part of the category tree
const { categoryModelRef, searchCategoryByPath } = useMopCategories(cacheId);

const pathCategories = getCategoriesInPath(path);
const isSearchRef = computed(() => getSearchQueryValue() !== undefined);

const gtmListTypeRef: Ref<GtmProductListType> = computed(() => {
  const hash = route.hash.split('=');
  const list = hash?.[0] === '#list' && decodeURIComponent(String(hash?.[1] ?? ''));
  return list || isSearchRef ? 'Searchresult_category' : 'Category';
});

const {
  initCategoryContent,
  categoryContentStateRef,
  enhancedPromoListTilesRef,
  boostedProductsRef,
  failedBoostedProductsRef,
  cmsStoryModelRef,
  loadingRef: loadingContent,
} = useMopCategoryListPage(category.getMopId());

function getHeadlineWithColor(categoryName: string, color: string) {
  if (!color) {
    return categoryName;
  }
  color = `${color.charAt(0).toUpperCase()}${color.slice(1)}`;
  return $mopI18n.t('common.category.heading_with_color', [categoryName, color]);
}

const categoryHeadlineRef = computed<CategoryHeadline>(() => {
  const headlineColor: string = indexableSingleColorFilterRef.value?.getValues()?.[0]?.getName() || '';
  const useHeadlineFromCms = !headlineColor && categoryContentStateRef.value.headline;
  // Headline from CMS
  if (useHeadlineFromCms) {
    const headline = categoryContentStateRef.value.headline;
    return {
      h1: headline,
      seo: headline,
    };
  }

  // Headline from translations
  const translationKey = 'common.category.heading.' + pathCategories[1]?.getMopId().match(/-(.*)/)?.[1];
  if ($mopI18n.te(translationKey)) {
    let headline = pathCategories.length > 3 ? pathCategories[2].getName($mopConfig) : category.getName($mopConfig);
    let seoHeadline = category.getName($mopConfig);
    if (headlineColor) {
      headline = getHeadlineWithColor(headline, headlineColor);
      seoHeadline = getHeadlineWithColor(seoHeadline, headlineColor);
    }
    return {
      h1: $mopI18n.t(translationKey, [headline, pathCategories[0].getName($mopConfig)]).toString(),
      seo: $mopI18n.t(translationKey, [seoHeadline, pathCategories[0].getName($mopConfig)]).toString(),
    };
  }

  // Headline from category name
  let headline: string = category.getName($mopConfig);
  if (headlineColor) {
    headline = getHeadlineWithColor(headline, headlineColor);
  }
  return {
    h1: headline,
    seo: headline,
  };
});

const productListRef = computed(() => {
  if (isSearchRef.value) {
    if (isFuzzySearchRef.value && !isStoryblokLivePreview) {
      return sortProductModelListByFuzzy();
    }
    return productModelListRef.value.getProductModelList();
  }

  // Merge productlistref with boosted products
  const mergedProductList: ProductModel[] = [
    ...boostedProductsRef.value.map((boostedProduct) => boostedProduct.product),
    ...productModelListRef.value.getProductModelList().filter((product) => {
      return !boostedProductsRef.value.find((boostedProduct) => boostedProduct.productId === product.getMopId());
    }),
  ] as ProductModel[];

  return mergedProductList;
});

const listRef = computed(() => {
  const returnList: ProductModel[] | CmsPromoListTile[] = [];

  let position = 1;
  let productIndex = 0;
  while (productIndex < productListRef.value.length) {
    let foundPromotionTiles = false;
    enhancedPromoListTilesRef.value.forEach((promoTile) => {
      if (promoTile.position !== position) {
        return;
      }
      (returnList as CmsPromoListTile[]).push(promoTile);
      foundPromotionTiles = true;
    });
    if (foundPromotionTiles) {
      position++;
      continue;
    }

    // Add next product from list
    (returnList as ProductModel[]).push(productListRef.value[productIndex]);
    position++;
    productIndex++;
  }
  return returnList;
});
const throttledEmit = throttle((loading) => {
  emit('load-state', loading);
}, 300);

if (!loadContentClientOnlyRef.value) {
  watch(
    [
      () => loadingProductsRef.value.loading,
      () => loadingProductsRefWithFuzzyFallback.value.loading,
      () => loadingFiltersRef.value.initFilters,
      () => (isFirstClientLoad() ? false : loadingContent.value.loading),
    ],
    (loaders) => {
      throttledEmit(loaders.some((isLoading) => isLoading));
    },
  );
}

function getSearchQueryValue() {
  return getQueryParamValue(constants.QUERY_PARAMETERS.SEARCH_QUERY);
}

const isGuestBuyerPlpPromoItemVisibleRef = computed(() => {
  return (
    listRef.value.length >= 20 &&
    customerModelRef.value.isGuest() &&
    !$breakpoint.isMobileRef.value &&
    !$breakpoint.isMediumRef.value &&
    $mopConfig.isGuestBuyerMarketingEnabled()
  );
});

const isPlainFirstPageRef = computed<boolean>(() => {
  // Only show content top, content bottom and promo tiles, if no filter, sorting and pagination parameter is applied
  return (
    !loadContentClientOnlyRef.value &&
    !appliedFiltersRef.value?.length &&
    !isSearchRef.value &&
    !getQueryParamValue(constants.QUERY_PARAMETERS.SORT_QUERY) &&
    !getQueryParamValue(constants.QUERY_PARAMETERS.PAGE_QUERY)
  );
});

const fillUpProductListRef = computed(() => {
  if (!isClient || !isPlainFirstPageRef.value) {
    return [];
  }

  const productTileCount = productListRef.value.length;
  if (paginationModelRef.value.getLastPage() <= 1) {
    return [];
  }
  const promoTilesCount = enhancedPromoListTilesRef.value.reduce((sum, enhancedPromoTile) => {
    if (enhancedPromoTile.layout === 'full') {
      return sum + 4;
    }
    return sum + (enhancedPromoTile.layout === 'double' ? 2 : 1);
  }, 0);

  const tilesPerRow =
    $breakpoint.isTinyRef.value || $breakpoint.isSmallRef.value || $breakpoint.isMediumRef.value ? 2 : 4;

  const tilesCount = productTileCount + promoTilesCount + (isGuestBuyerPlpPromoItemVisibleRef.value ? 1 : 0);
  const missingTilesCount = tilesCount % tilesPerRow ? tilesPerRow - (tilesCount % tilesPerRow) : 0;
  if (!missingTilesCount) {
    return [];
  }

  return productModelListRef.value.getProductModelList().slice(0, missingTilesCount);
});

function setIndexableSingleColorFilter() {
  if (!isSeoFilterUrlsEnabled) {
    return;
  }
  const isIndexableSingleColorFilterWithSingleValue =
    appliedFiltersRef.value?.length === 1 &&
    appliedFiltersRef.value[0].getSlug() === FILTER_COLOR &&
    appliedFiltersRef.value[0].getValues().length === 1;
  if (isIndexableSingleColorFilterWithSingleValue && appliedFiltersRef.value) {
    indexableSingleColorFilterRef.value = appliedFiltersRef.value[0];
  }
}

async function loadContent() {
  if (!isSearchRef.value) {
    const checkIfFiltersAreSet = !isSeoFilterUrlsEnabled;
    // needs to be SSR for canonicals to work
    await initFilters(checkIfFiltersAreSet);
    setAppliedSortingFromUrl();
    setIndexableSingleColorFilter();

    await Promise.allSettled([
      searchCategoryByPath(path, true),
      initCategoryContent({ loadTiles: isPlainFirstPageRef.value, cacheClienResponse: true }),
      isClient &&
        searchProducts(
          {
            categoryId: category.getId(),
            page: Number(route.query.page || 1),
            applyFilters: true,
            includeSaleProducts: category.getProductQueryIncludeProductsOnSale(),
          },
          true,
        ),
    ]);
  } else {
    await searchProductsWithFuzzyFallback({
      searchTerm: getSearchQueryValue() || '',
      page: Number(route.query.page || 1),
      limit: $mopConfig.getMaxProductsPerPage(),
      categoryId: category.getId(),
      forceClientRefetch: true,
    });

    productModelListRef.value = productModelListRefWithFuzzyFallback.value;
    paginationModelRef.value = paginationModelRefWithFuzzyFallback.value;
  }

  updateProductCount(paginationModelRef.value.getTotal());

  if (isSearchRef.value && productListRef.value.length === 0) {
    emit('empty-search');
  }
}

useAsyncData(async () => {
  if (!loadContentClientOnlyRef.value) {
    await loadContent();
  } else {
    !isSearchRef.value &&
      (await initCategoryContent({ loadTiles: isPlainFirstPageRef.value, cacheClienResponse: true }));
  }
});

function getFilterCount(name: string): number {
  return ((route.query[name] as string)?.split(',') || []).length;
}
function urlContainsblackListedQueryParameters() {
  return blackListedQueryParameters.some((key) => key !== FILTER_COLOR && route.query[key]);
}

const seoHeadRef = computed(() => {
  const isPageIndexable = () => {
    if (loadContentClientOnlyRef.value) {
      return false;
    }
    if (Object.keys(route.query).length === 0) {
      return true;
    }

    const indexableFilterCount = getFilterCount(FILTER_COLOR);
    return !urlContainsblackListedQueryParameters() && isSeoFilterUrlsEnabled && indexableFilterCount <= 1;
  };

  const getTitle = (seoDataTitle: string, isTitleOverwrite: boolean) => {
    const titleFromTranslations = $mopI18n.t('common.meta.plp.title-with-category-heading', [
      categoryHeadlineRef.value.seo,
    ]);
    if (isTitleOverwrite) {
      return titleFromTranslations;
    }
    return seoDataTitle || getSearchQueryValue() || titleFromTranslations;
  };

  const getDescription = (seoDataDescription: string, title: string, isTitleOverwrite: boolean) => {
    const descriptionFromTranslations = $mopI18n.t('common.meta.default.description');
    if (isTitleOverwrite) {
      return `${descriptionFromTranslations} - ${categoryHeadlineRef.value.seo}`;
    }
    return seoDataDescription || `${descriptionFromTranslations} - ${title}`;
  };

  const seoData = cmsStoryModelRef.value.getSeo();

  const isIndexableFilterColor = Boolean(indexableSingleColorFilterRef.value?.getValues()?.[0]?.getName());
  const isTitleOverWriteThroughFilter = isIndexableFilterColor;
  const title = getTitle(seoData.title, isTitleOverWriteThroughFilter);
  const description = getDescription(seoData.description, title, isTitleOverWriteThroughFilter);
  const isIndexable =
    seoData.isIndexable !== false &&
    isPageIndexable() &&
    !constants.DISALLOWED_ALTERNATE_SLUGS.includes($mopI18n.locale);

  let canonical = cmsStoryModelRef.value.getCanonical() || route.path;
  if (isIndexable && isIndexableFilterColor) {
    canonical = `${canonical}?${FILTER_COLOR}=${route.query[FILTER_COLOR]}`;
  }
  const alternates = categoryModelRef.value.getAlternates();
  setAlternates(alternates);
  const headObject = getHeadObject({
    title,
    description,
    isIndexable,
    canonical,
    alternates,
  });

  const seoBreadcrumbs: SeoBreadcrumb[] = getCategoryBreadcrumbs(categoryModelRef.value);
  headObject.script.push(getBreadcrumbSchema(seoBreadcrumbs));

  return headObject;
});

useHead({
  title: () => seoHeadRef.value?.title as any,
  meta: () => seoHeadRef.value?.meta as any,
  link: () => seoHeadRef.value?.link as any,
  script: () => seoHeadRef.value?.script as any,
});

onMounted(async () => {
  watch(activeCategoryPathRef, () => {
    // Ensure fresh filter overlay on category change
    overlay.closeAll();
  });

  const stopCategoryModelWatch = watch(
    categoryModelRef,
    (categoryModel) => {
      if (!categoryModelRef.value.isInitialized()) {
        return;
      }

      $gtm2.trackPageView({
        pageType: 'CategoryPage',
        pageCategoryId: categoryModel.getMopId(),
      });
      nextTick(() => {
        stopCategoryModelWatch();
      });
    },
    { immediate: true },
  );

  if (loadContentClientOnlyRef.value) {
    emit('load-state', true);
    await loadContent();
    emit('load-state', false);
  }
});

const categoryNavStickyConfig: ObserveScrollConfig = {
  calculateActionPositions: (element) => {
    if (!element) {
      return [];
    }
    const stickyStartPosition = element.offsetTop;
    const actionPositionNotSticky: ActionPosition = {
      onEnter: () => {
        isNavStickyRef.value = false;
        isNavPrepareStickyRef.value = false;
      },
      positionStart: 0,
      positionEnd: stickyStartPosition,
    };

    const actionPositionSticky: ActionPosition = {
      onEnter: () => {
        isNavPrepareStickyRef.value = true;
        isNavStickyRef.value = true;
      },
      positionStart: stickyStartPosition,
    };

    const actionPositionScrollUp: ActionPosition = {
      onEnter: () => {
        isNavStickyRef.value = false;
        isNavPrepareStickyRef.value = true;
      },
      positionStart: stickyStartPosition,
      scrollDirection: constants.SCROLL_DIRECTION_UP,
    };

    return [actionPositionNotSticky, actionPositionSticky, actionPositionScrollUp];
  },
};

function isBoostedProduct(product: ProductModel) {
  return boostedProductsRef.value.some((boostedProduct) => boostedProduct.productId === product.getMopId());
}

function getBoostedProductData(product: ProductModel) {
  return boostedProductsRef.value.find((boostedProduct) => boostedProduct.productId === product.getMopId()) || {};
}
</script>

<template>
  <div
    :class="[
      'category-list',
      {
        'category-list--initial': isFirstClientLoad(),
        'category-list--loading': productListRef.length === 0,
        'category-list--sticky-nav-prepare': isNavPrepareStickyRef,
        'category-list--sticky-nav': isNavStickyRef,
      },
    ]"
  >
    <MopBreadcrumbs />
    <div v-observe-scroll="categoryNavStickyConfig" class="category-list__nav-wrapper">
      <div
        :class="[
          'category-list__nav',
          {
            'lock-scroll-padding': isNavPrepareStickyRef,
          },
        ]"
      >
        <CategoryTopNavigation
          v-if="pathCategories.length > 0 || isSearchRef"
          :headline="getSearchQueryValue() ?? categoryHeadlineRef.h1"
          :path-categories="!isSearchRef ? pathCategories : undefined"
          :show-filter="!isSearchRef"
        />
      </div>
    </div>

    <div
      v-if="isPlainFirstPageRef && categoryContentStateRef.topElement && categoryContentStateRef.topElement.length"
      class="category-list__content-top"
    >
      <ClientOnly>
        <MopCmsContentElementsSlot
          id="category-list-page-top"
          :elements="categoryContentStateRef.topElement"
          :campaign-name="categoryContentStateRef.campaignName"
        />
      </ClientOnly>
    </div>
    <div class="category-list__result">
      <div class="category-list__product-grid">
        <div class="category__loading">
          <i class="category__loading-spinner icon icon--loading-asset" />
        </div>
        <ClientOnly>
          <template v-if="$storyblokLivePreview.isEnabledInIframe">
            <div class="category-list__failed-list">
              <div
                v-for="(failedElement, i) in failedBoostedProductsRef"
                :key="`failed-${i}-${failedElement._uid}`"
                v-storyblok-editable="failedElement"
                class="category-list__failed-element"
              >
                {{ 'Live Preview only - Error' }}<br />
                {{ 'Boosted product:' }} {{ failedElement.productId }}
              </div>
            </div>
          </template>
          <UiGrid class="category-list__grid">
            <template v-for="(listItem, index) in listRef">
              <UiGridItem
                v-if="(listItem as CmsPromoListTile).layout"
                :key="`${(listItem as CmsPromoListTile).key}`"
                :element-id="(listItem as any)._uid"
                :layout="(listItem as CmsPromoListTile).layout"
                :visibility="(listItem as any).visibility"
                :custom-id="(listItem as CmsPromoListTile).customId"
                :data-position="(listItem as CmsPromoListTile).position"
              >
                <component
                  :is="(listItem as any).name"
                  v-if="(listItem as CmsPromoListTile).isVisible !== false"
                  enable-popularity-flag
                  :data="(listItem as CmsPromoListTile).element"
                  :image-type="(listItem as CmsPromoListTile).element.imageType"
                />
              </UiGridItem>

              <MopProductTile
                v-else
                :key="`${(listItem as ProductModel).getMopId()}-${(listItem as ProductModel).getPrice().salePrice}}`"
                v-storyblok-editable="
                  $storyblokLivePreview.isEnabledInIframe && getBoostedProductData(listItem as ProductModel)
                "
                :class="{
                  'category-list__boosted-product':
                    $storyblokLivePreview.isEnabledInIframe && isBoostedProduct(listItem as ProductModel),
                }"
                :disable-click="$storyblokLivePreview.isEnabledInIframe && isBoostedProduct(listItem as ProductModel)"
                :position="index"
                :product="listItem as ProductModel"
                :category-path="path"
                enable-popularity-flag
                image-type="product-tile"
                :gtm-list-type="gtmListTypeRef"
                :data-cy="`category-list-product-tile-${index}`"
              />
            </template>
            <MopCmsGuestBuyerPlpPromotionItem v-if="isGuestBuyerPlpPromoItemVisibleRef" />
            <MopProductTile
              v-for="(product, index) in fillUpProductListRef"
              :key="`fillup-${product.getMopId()}`"
              :position="index + 1000"
              :product="product"
              :gtm-list-type="gtmListTypeRef"
              :category-path="path"
            />
          </UiGrid>
          <UiPagination
            class="category-list__pagination"
            :total-pages="Math.ceil(paginationModelRef.getTotal() / $mopConfig.getMaxProductsPerPage())"
            :page="$route.query.page?.toString()"
            :total-items-text="$mopI18n.t('components.plp.page_count', [paginationModelRef.getTotal()])"
            :get-locale-path-by-page="getLocalePathByPage"
          />
        </ClientOnly>
      </div>
    </div>

    <div class="category-list__content-middle">
      <MopCmsContentElementsSlot
        v-if="isPlainFirstPageRef"
        id="category-list-page-middle"
        :elements="categoryContentStateRef.middleElement"
        :campaign-name="categoryContentStateRef.campaignName"
        lazy-load
      />
    </div>

    <ClientOnly>
      <MopRecommendation
        class="category-list__recommendation"
        widget="CATEGORY"
        :section="isSearchRef ? 'SEARCH' : 'PRODUCT_LIST_PAGE'"
        :category-path="path"
        show-slider-peek
      />
    </ClientOnly>

    <div class="category-list__content-bottom">
      <MopCmsContentElementsSlot
        v-if="isPlainFirstPageRef"
        id="category-list-page-bottom"
        :elements="categoryContentStateRef.bottomElement"
        :campaign-name="categoryContentStateRef.campaignName"
        lazy-load
      />
    </div>
    <CategoryBottomNavigation
      v-if="filterBottomNavigationListRef.length > 0"
      :category="category"
      class="category-list__navigation-bottom"
      :filter-list="filterBottomNavigationListRef"
    />
  </div>
</template>

<style lang="scss" scoped>
.category-list {
  margin-bottom: $space60;

  @include apply-upto(small) {
    margin-bottom: $space40;
  }
}

.category-list__nav-wrapper {
  background: $white;
  min-height: $mobile-header-height;
  max-height: $header-height;
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: $space5;
}

.category-list__nav {
  transition:
    transform 0.3s ease,
    opacity 0s 0.3s linear;
  width: 100%;
  max-height: inherit;
  display: flex;
  align-items: flex-end;

  @include apply-upto(medium) {
    align-items: center;
  }
}

.category-list--sticky-nav-prepare {
  .category-list__nav {
    @include z(layers, superbanner);

    transform: translateY(0);
    height: $header-height;
    top: -$header-height;
    width: auto;
    left: 0;
    right: 0;
    position: fixed;
    background: $white;
    padding-bottom: $space10;

    @include apply-upto(medium) {
      height: $mobile-header-height;
      top: -$mobile-header-height;
      padding-bottom: 0;
      padding-top: 0;
      // To make burger icon visible
      left: $space40;
    }

    @include apply-upto(small) {
      left: 0;
    }
  }
}

.category-list--sticky-nav-prepare.category-list--sticky-nav {
  .category-list__nav {
    transform: translateY(100%);
  }
}

.category-list__content-top {
  margin-bottom: $space15;
}

.category-list__result {
  display: flex;
  align-items: flex-start;

  @include apply-upto(medium) {
    flex-direction: column;
  }
}

.category-list__product-grid {
  position: relative;
  width: 100%;
  overflow-x: hidden;

  :deep(.cms-product-row) {
    margin-bottom: 0;
    padding-right: 0;
    padding-left: 0;
  }
}

.category-list__grid {
  overflow: hidden;
  padding: 0 $global-padding;

  @include apply-upto(small) {
    padding: 0;
  }
}

.category-list__recommendation {
  margin-top: $space60;
  padding: 0 $global-padding;
}

.category-list__failed-list {
  display: flex;
  padding: 0 $global-padding;
}

.category-list__failed-element {
  @include text-style(small);

  background: $signal-alert;
  color: $white;
  padding: $space5;
  margin: $space5;
}

.category-list__content-middle {
  margin-top: $space30;
}

.category-list__content-bottom {
  margin-top: $space60;
}

.category-list__boosted-product {
  position: relative;

  &::after {
    @include text-style(small);

    content: 'Live preview only - Boosted';
    opacity: 0.2;
    position: absolute;
    background: $signal-alert;
    color: $white;
    padding: $space10;
    left: $space40;
    top: 0;
    transform: translateX(-100%) rotate(-90deg);
    transform-origin: right;
    pointer-events: none;
  }

  &:hover {
    &::after {
      opacity: 1;
    }
  }
}

.category-list--loading {
  .category-list__product-grid {
    min-height: 100vh;
  }

  .category-list__pagination,
  .category-list__recommendation,
  .category-list__content-bottom,
  .category-list__content-middle,
  .category-list__content-top,
  .category-list__navigation-bottom {
    display: none;
  }
}

.category-list__empty-header {
  @include text-style(headline, true);

  margin: 0;
  padding: $space20 $space10;

  @include apply-from(extralarge) {
    padding: $space30 $space10;
  }
}

.category-list__pagination {
  margin-top: $space50;
  padding: 0 6px;
}
</style>
