<script setup lang="ts">
import type { NavigationItem } from '@mop/ui/types/uiComponents';
import type { CategoryModel } from '@/types/category';
import type { FilterModel } from '@/types/filters';

defineOptions({
  name: 'CategoryBottomNavigation',
});

const props = defineProps({
  category: {
    type: Object as PropType<CategoryModel>,
    required: true,
  },
  filterList: {
    type: Array as PropType<FilterModel[]>,
    default: () => [],
  },
});

const { $mopConfig, $mopI18n } = useNuxtApp();
const navigationItemsRef = computed(() => {
  const items: NavigationItem[] = [];
  const categoryName: string = props.category.getName($mopConfig);
  if (!categoryName) {
    return [];
  }
  const url = props.category.getUrl();

  props.filterList.forEach((filter) => {
    const parameterName: string = filter.getSlug();
    filter.getValues().forEach((value) => {
      items.push({
        id: value.getKey(),
        url: `${$mopI18n.localePath(url)}?${parameterName}=${value.getValue()}`,
        name: $mopI18n.t('common.category.heading_with_color', [categoryName, value.getName()]),
      });
    });
  });

  return items;
});
</script>

<template>
  <div class="category-bottom-navigation">
    <UiScrollNavigation :navigation-items="navigationItemsRef" />
  </div>
</template>

<style scoped lang="scss">
.category-bottom-navigation {
  padding: $space5 $global-padding;

  &:deep(.ui-mop-scroll-navigation) {
    margin: 0;
  }
}
</style>
