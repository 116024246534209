<script setup lang="ts">
defineOptions({
  name: 'CategoryLandingPage',
});

const emit = defineEmits(['load-state']);

const { $storyblokLivePreview, $gtm2 } = useNuxtApp();
const route = useRoute();
const overlay = useMopOverlay();
const cacheId = getCacheIdByRoute(route);
const path = (route.params.path as string) ?? '';
const { getCategoryByPath, getCategoriesInPath } = useMopCategoryTree();
const category = getCategoryByPath(path);
const pathCategories = getCategoriesInPath(path);
const categoryNames = pathCategories.map((category) => category.getSlug());
const { categoryModelRef, searchCategoryByPath } = useMopCategories(cacheId);
const { getCmsStoryByConfigId, cmsStoryModelRef, loadingRef } = useMopCms(cacheId);
const { handleHeadForCmsPage } = useMopSeo();
const isHomePageRef = ref(categoryModelRef.value.isHomeCategory());
const cheeringMessageRef = ref<string>('');

watch(
  () => loadingRef.value.loading,
  (isLoading) => {
    emit('load-state', isLoading);
  },
);

const seoHeadRef = computed(() => {
  if (!cmsStoryModelRef.value.isInitialized() || cmsStoryModelRef.value.hasError()) {
    return;
  }
  return handleHeadForCmsPage(cmsStoryModelRef.value);
});

useHead({
  title: () => seoHeadRef.value?.title as any,
  meta: () => seoHeadRef.value?.meta as any,
  link: () => seoHeadRef.value?.link as any,
});

useAsyncData(async () => {
  await Promise.allSettled([
    searchCategoryByPath(path),
    getCmsStoryByConfigId('mopCategoryId', category.getMopId(), {
      excluding_fields: 'bodyFlyout',
    }),
  ]);
  isHomePageRef.value = categoryModelRef.value.isHomeCategory();
});

onMounted(async () => {
  await overlay.closeAll();

  const stopCategoryModelWatch = watch(
    categoryModelRef,
    (categoryModel) => {
      if (!categoryModelRef.value.isInitialized()) {
        return;
      }

      $gtm2.trackPageView({
        pageType: isHomePageRef.value ? 'home' : 'CategoryPage',
        pageCategoryId: categoryModel.getMopId(),
      });

      nextTick(() => {
        stopCategoryModelWatch();
      });
    },
    { immediate: true },
  );

  watch(
    cmsStoryModelRef,
    () => {
      if (!cmsStoryModelRef.value.isInitialized()) {
        return;
      }

      useMopPromotionProducts().initPromotionProducts(cmsStoryModelRef.value.getContentElements('bodyTop'));
    },
    { immediate: true },
  );

  handleCheeringMessage();
});

async function handleCheeringMessage() {
  const { getWelcomeCheeringMessage, showWelcomeCheeringMessage } = useMopCheeringMessageClient();
  if (showWelcomeCheeringMessage()) {
    cheeringMessageRef.value = await getWelcomeCheeringMessage();
  }
}

$storyblokLivePreview.initStoryListener('Storyblok', cmsStoryModelRef, true);
</script>

<template>
  <div class="category-landing">
    <div class="category__loading">
      <i class="category__loading-spinner icon icon--loading-asset" />
    </div>
    <div class="category-landing__content">
      <ClientOnly>
        <div v-if="cheeringMessageRef && isHomePageRef" class="category-landing__cheering">
          <MopCheeringMessage
            :message="cheeringMessageRef"
            background-color="purple"
            text-center
            :fade-out-in-seconds="7"
          />
        </div>
      </ClientOnly>
      <!-- Keep divs, otherwise urls on page stops working... -->
      <div>
        <MopCmsContentElementsSlot
          id="category-landing-page"
          data-id="category-landing-page"
          :elements="cmsStoryModelRef.getContentElements('bodyTop')"
          :campaign-name="cmsStoryModelRef.getCampaignName()"
        />
      </div>
      <ClientOnly>
        <div v-if="cmsStoryModelRef.getAttribute('enableRecommendations')">
          <MopRecommendation
            class="category-landing__recommendation"
            :selector="categoryNames"
            widget="HOMEPAGE"
            section="CATEGORY_LANDING_PAGE_B"
            :category-path="path"
            show-slider-peek
          />
        </div>
      </ClientOnly>
      <div>
        <MopCmsContentElementsSlot
          id="category-landing-page-bottom"
          data-id="category-landing-page"
          :elements="cmsStoryModelRef.getContentElements('bodyBottom')"
          :campaign-name="cmsStoryModelRef.getCampaignName()"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.category-landing {
  position: relative;
  margin-bottom: $space60;

  @include apply-upto(medium) {
    margin-bottom: $space40;
  }
}

.category-landing__recommendation {
  margin-top: $space50;
  padding: 0 $global-padding;
}

.category-landing__cheering {
  position: absolute;
  left: 0;
  right: 0;
  margin-top: $space100;
  margin-left: $global-padding;
  margin-right: $global-padding;
}
</style>
