import type { WatchStopHandle } from 'vue';
import { isClient, getHashFromString } from '@mop/shared/utils/util';
import { securedWrap } from '@mop/shared/utils/securedWrap';
import { localStorageGet } from '@mop/shared/utils/localStorage';
import { getElementUiObject } from '@mop/cms/utils/utils';
import type { CmsPromoTileLayout } from '@mop/cms/types';
import type { CmsContentElementModel, EditableCmsComponent } from '@/types/cms';
import type { CustomerGroup } from '@/types/customer';
import type { ProductModel } from '@/types/product';

type CategoryContentState = {
  storyblokEditable: any;
  headline: string;
  topElement: CmsContentElementModel[];
  middleElement: CmsContentElementModel[];
  bottomElement: CmsContentElementModel[];
  campaignName: string;
};

export type CmsBoostedProduct = EditableCmsComponent & {
  productId: string;
  product?: ProductModel;
};

export type CmsPromoListTile = {
  layout?: CmsPromoTileLayout;
  position?: number;
  isVisible?: boolean;
  customId?: string;
  name?: string;
  key?: any;
  element: any;
};

type LoadingState = Ref<{ loading: boolean }>;

export default function useMopCategoryListPage(mopCategoryId: string) {
  const {
    getCmsStoryByConfigId,
    cmsStoryModelRef,
    loadingRef: loadingCms,
  } = useMopCms(`${mopCategoryId}-category-list-page`);
  const { setLoadingState } = useMopPageTransitionClient();
  const { $storyblokLivePreview, $breakpoint } = useNuxtApp();
  const isStoryblokLivePreview = $storyblokLivePreview.isEnabled;
  const enhancedPromoListTilesRef = ref<CmsPromoListTile[]>([]);
  const boostedProductsRef = ref<CmsBoostedProduct[]>([]);
  const failedBoostedProductsRef = ref<CmsBoostedProduct[]>([]);
  const { customerModelRef } = useMopCustomer();
  const isMobileDeviceRef = computed(() => ['xs', 's'].includes($breakpoint.currentRef.value));
  let customerGroups = customerModelRef.value.getCustomerGroups();
  if (isStoryblokLivePreview && localStorageGet(constants.LOCAL_STORAGE.PREVIEW_CUSTOMER_GROUP)) {
    // Delay to avoid server client hydration issues
    setTimeout(() => {
      customerGroups = [localStorageGet(constants.LOCAL_STORAGE.PREVIEW_CUSTOMER_GROUP) as CustomerGroup];
      initPromoTiles();
    }, 500);
  }
  const loadingRef: LoadingState = ref({
    loading: false,
  });
  setLoadingState(loadingRef, loadingCms);
  let stopWatch: WatchStopHandle;
  async function initCategoryContent({
    loadTiles = true,
    cacheClienResponse = false,
  }: {
    loadTiles?: boolean;
    cacheClienResponse?: boolean;
  }) {
    await getCmsStoryByConfigId(
      'mopCategoryId',
      mopCategoryId,
      {
        excluding_fields: 'bodyFlyout',
      },
      cacheClienResponse,
    );

    if (loadTiles && isClient) {
      stopWatch = watch(
        [cmsStoryModelRef, isMobileDeviceRef],
        () => {
          initPromoTiles();
          if (isStoryblokLivePreview) {
            initBoostedProducts();
          }
        },
        { immediate: true },
      );
    }
  }

  onBeforeUnmount(() => {
    stopWatch && stopWatch();

    enhancedPromoListTilesRef.value = [];
    boostedProductsRef.value = [];
    failedBoostedProductsRef.value = [];
  });

  function getBoostedProductFromContentElement(
    data: CmsContentElementModel,
    product?: ProductModel,
  ): CmsBoostedProduct | undefined {
    const element: any = data.getData();
    if (!element) {
      return;
    }

    return getElementUiObject(element, {
      productId: element.id,
      product,
    } as CmsBoostedProduct);
  }

  async function initBoostedProducts() {
    const {
      productModelListRef: boostedProductModelListRef,
      searchProductsByMopProductIds: boostedSearchProductsByIds,
    } = useMopProducts();
    failedBoostedProductsRef.value = [];
    const elementList: CmsContentElementModel[] =
      cmsStoryModelRef.value?.getContentElements('bodyBoostedProducts') ?? [];
    const mopProductIdList = elementList.reduce((mopProductIds: string[], contentElement) => {
      const data = contentElement.getData();
      if (data.id) {
        mopProductIds.push(data.id);
      }
      return mopProductIds;
    }, []);

    await boostedSearchProductsByIds(mopProductIdList, isStoryblokLivePreview);
    const products = boostedProductModelListRef.value.getProductModelList();

    boostedProductsRef.value = elementList.reduce((elements: CmsBoostedProduct[], element) => {
      const data: any = element.getData();
      const boostedProduct = getBoostedProductFromContentElement(element);
      if (!data.id) {
        boostedProduct && failedBoostedProductsRef.value.push(boostedProduct);
        return elements;
      }
      const product = products.find((productItem) => productItem.getMopId() === data.id);
      if (product === undefined || product.isSoldOut()) {
        boostedProduct && failedBoostedProductsRef.value.push(boostedProduct);
        return elements;
      }
      const fallbackBoostedProduct = getBoostedProductFromContentElement(element, product);
      fallbackBoostedProduct && elements.push(fallbackBoostedProduct);
      return elements;
    }, []);
  }

  function getComponentKey(element: CmsContentElementModel) {
    if (!isStoryblokLivePreview) {
      return element.getUid();
    }
    return getHashFromString(JSON.stringify(element.getData()));
  }

  function getComponentData(element: CmsContentElementModel) {
    return element.getData();
  }

  function initPromoTiles() {
    const elementList: CmsContentElementModel[] =
      cmsStoryModelRef.value?.getContentElements(['bodyPromotionList']) ?? [];

    const promoListTiles: CmsPromoListTile[] = [];
    elementList.forEach((contentElement) => {
      const element: any = getComponentData(contentElement);
      const position: number = parseInt(element.position);
      if (!element || !position) {
        return;
      }

      const deviceVisibility = contentElement.getDeviceVisibility();
      if (
        (deviceVisibility === 'desktop' && isMobileDeviceRef.value) ||
        (deviceVisibility === 'mobile' && !isMobileDeviceRef.value)
      ) {
        return;
      }
      const visibility = contentElement.getVisibility();
      const isVisible = isVisibleForCustomer(visibility, customerGroups);
      if (!isVisible) {
        return;
      }

      promoListTiles.push({
        element,
        name: contentElement.getVueComponentName(),
        isVisible,
        customId: contentElement.getCustomId(),
        key: getComponentKey(contentElement),
        position,
        layout: element.layout || 'full',
      });
    }, []);

    if (isClient) {
      useMopPromotionProducts().initPromotionProducts(
        cmsStoryModelRef.value?.getContentElements(['bodyPromotionList', 'bodyTop', 'bodyMiddle']) ?? [],
      );
    }

    if (promoListTiles.length === 0) {
      return;
    }

    enhancedPromoListTilesRef.value = promoListTiles;
  }

  $storyblokLivePreview.initStoryListener('CategoryListPage', cmsStoryModelRef, true);

  const categoryContentStateRef: Ref<CategoryContentState> = computed(() => {
    return {
      storyblokEditable: cmsStoryModelRef.value?.getContentEditableObject(),
      headline: cmsStoryModelRef.value?.getAttribute('headline') ?? '',
      topElement: cmsStoryModelRef.value?.getContentElements('bodyTop') ?? [],
      middleElement: cmsStoryModelRef.value?.getContentElements('bodyMiddle') ?? [],
      bottomElement: cmsStoryModelRef.value?.getContentElements('bodyBottom') ?? [],
      campaignName: cmsStoryModelRef.value?.getCampaignName(),
    };
  });

  return securedWrap({
    initCategoryContent,
    categoryContentStateRef,
    enhancedPromoListTilesRef,
    boostedProductsRef,
    failedBoostedProductsRef,
    cmsStoryModelRef,
    loadingRef,
  });
}
