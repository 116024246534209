import { securedWrap } from '@mop/shared/utils/securedWrap';

type FilterOverlayComposableStorage = {
  showFilterUsedCheeringMessageRef: Ref<boolean>;
  filterUsedCheeringMessageRef: Ref<string>;
};

export default function useMopFilterOverlay() {
  const storage = initStorage<FilterOverlayComposableStorage>('useFilterOverlay');

  const filterOverlayComponentName = 'MopCategoryFilterOverlay';
  const overlay = useMopOverlay();
  const { getFilterUsedCheeringMessage, showFilterUsedCheeringMessage } = useMopCheeringMessageClient();

  // Keep showFilterUsedCheeringMessageRef here, because Category List Page components are rerendered and loosing reactivity
  const showFilterUsedCheeringMessageRef: Ref<boolean> =
    storage.get('showFilterUsedCheeringMessageRef') ??
    storage.saveAndGet('showFilterUsedCheeringMessageRef', ref(false));

  const filterUsedCheeringMessageRef: Ref<string> =
    storage.get('filterUsedCheeringMessageRef') ?? storage.saveAndGet('filterUsedCheeringMessageRef', ref(''));

  function toggleFilterOverlay() {
    if (
      overlay?.activeOverlayRef?.value?.componentName === filterOverlayComponentName &&
      overlay.activeOverlayRef.value.isOpen
    ) {
      return overlay.closeAll();
    }
    const route = useRoute();
    const urlOnOpen = route.fullPath;
    return overlay.open({
      type: 'right',
      componentName: filterOverlayComponentName,
      overrideParams: {
        lockScroll: true,
        forceRender: true,
        closeOnMouseLeave: true,
        mouseLeaveTimeout: 1000,
        persistRoutes: [constants.ROUTE.NAMES.CATEGORY_PAGE],
      },
      onOpen: () => {
        showFilterUsedCheeringMessageRef.value = false;
      },
      onClose: async () => {
        const hasFilterChanged = urlOnOpen !== route.fullPath;
        if (!hasFilterChanged) {
          return;
        }
        const showCheeringMessage = showFilterUsedCheeringMessage();
        if (showCheeringMessage) {
          showFilterUsedCheeringMessageRef.value = true;
          filterUsedCheeringMessageRef.value = await getFilterUsedCheeringMessage();
          setTimeout(() => {
            showFilterUsedCheeringMessageRef.value = false;
          }, 7000);
        }
      },
    });
  }
  return securedWrap({
    filterOverlayComponentName,
    toggleFilterOverlay,
    showFilterUsedCheeringMessageRef,
    filterUsedCheeringMessageRef,
  });
}
