<script setup lang="ts">
import type { RouteLocationNormalizedLoaded } from 'vue-router';
import type { SeoBreadcrumb } from '@/types/cms';
import CategoryList from '@/views/category/CategoryList.vue';
import CategoryLanding from '@/views/category/CategoryLanding.vue';
import CategorySearchNoResultPage from '@/views/category/CategorySearchNoResultPage.vue';

const PAGE = 'category';

defineOptions({
  name: 'CategoryPage',
});

const emit = defineEmits(['page-not-found']);

const { $mopConfig } = useNuxtApp();
const route = useRoute();

const classListRef = ref<string[]>([]);
if (isFirstClientLoad()) {
  classListRef.value.push('category--initial');
}
const { getCategoryByPath, getCategoryBreadcrumbs } = useMopCategoryTree();
const { initTransition } = useMopPageTransitionClient(PAGE);
const { setActiveCategory } = useMopRouter();
const { setHeaderBreadcrumbs } = useMopHeader();
const {
  QUERY_PARAMETERS: { SEARCH_QUERY },
} = constants;
const LIST_PAGE = 'CategoryList';
const LANDING_PAGE = 'CategoryLanding';
const SEARCH_NO_RESULT_PAGE = 'CategorySearchNoResultPage';
const categoryPageRef = ref(LIST_PAGE);
const pageStateRef = ref(getPageState());
const loadingRef = ref({ loading: false });

initTransition(classListRef, loadingRef);

function getKey(route: RouteLocationNormalizedLoaded) {
  const urlPath = `${route.path}${route.query ? JSON.stringify(route.query) : ''}`;
  return `${urlPath}${categoryPageRef.value}`;
}

watch(
  () => route.query,
  (query, oldQuery) => {
    if (query[SEARCH_QUERY] !== oldQuery[SEARCH_QUERY]) {
      categoryPageRef.value = LIST_PAGE;
    }
    pageStateRef.value = getPageState();
  },
);

function getPageState() {
  const isSearch: boolean = (route.name as string).includes('search') || route.query[SEARCH_QUERY] !== undefined;
  let page: string = categoryPageRef.value;
  const key = getKey(route);
  const category = getCategoryByPath(route.params.path as string);
  const isLandingPage: boolean = $mopConfig.getCategoryPreference(category.getMopId()).isLandingPage || false;
  let breadcrumbs: SeoBreadcrumb[] = [];
  if (category.isInitialized()) {
    setActiveCategory(category.getId());
    breadcrumbs = getCategoryBreadcrumbs(category);
    setHeaderBreadcrumbs(breadcrumbs);
  }

  let component = page === SEARCH_NO_RESULT_PAGE ? markRaw(CategorySearchNoResultPage) : markRaw(CategoryList);

  if (isSearch) {
    return {
      isSearch,
      page,
      component,
      key,
    };
  }
  if (!category.isInitialized()) {
    emit('page-not-found');
    return {};
  } else if (isLandingPage) {
    page = LANDING_PAGE;
    component = markRaw(CategoryLanding);
  }

  return {
    category,
    page,
    component,
    key,
  };
}

function handleLoadState(loading: any) {
  loadingRef.value.loading = loading;
}

function handleEmptySearch() {
  categoryPageRef.value = SEARCH_NO_RESULT_PAGE;
  pageStateRef.value = getPageState();
}
</script>

<template>
  <div>
    <div v-if="pageStateRef && pageStateRef.page !== '' && pageStateRef.key !== ''">
      <template v-if="pageStateRef.isSearch">
        <ClientOnly>
          <component
            :is="pageStateRef.component"
            :key="pageStateRef.key"
            :class="classListRef"
            @load-state="handleLoadState"
            @empty-search="handleEmptySearch"
          />
        </ClientOnly>
      </template>
      <component
        :is="pageStateRef.component"
        v-else
        :key="pageStateRef.key"
        :class="classListRef"
        @load-state="handleLoadState"
        @empty-search="handleEmptySearch"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.category--initial,
.category-landing,
.category-list {
  :deep(.category__loading) {
    display: none;
    position: sticky;
  }

  :deep(.category__loading-spinner) {
    transition: opacity 0.5s;
    opacity: 0;
    visibility: hidden;
    position: absolute;
    top: calc(50vh - 200px);
    left: 50%;
    margin-left: math.div(-$spinner-size, 2);
  }
}

.category--loading,
.category-list--loading {
  :deep(.category__loading) {
    @include z(global, abovecontent);

    display: block;
    position: sticky;
    background: $white;
    top: 0;
    left: 0;
    right: 0;
  }

  :deep(.category__loading-spinner) {
    opacity: 1;
    visibility: visible;
  }

  // set up before enter animations
  :deep(.category-list__grid, .category-landing__content) {
    opacity: 0;
  }
}

.category--enter {
  :deep(.category__loading) {
    transition: all #{$transition-enter - 0.3s};
    opacity: 0;
    max-height: 0;
  }

  :deep(.category-list__grid, .category-landing__content, .category-list__content-top, .category-filter-bar-wrapper) {
    transition: opacity $transition-enter;
    opacity: 1;
  }

  :deep(.category-filter-bar-wrapper) {
    pointer-events: all;
  }
}

.category--leave {
  display: none;
}
</style>
